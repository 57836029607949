/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2019-12-10 18:04:09
 * @Last Modified by: zhujial
 * @Last Modified time: 2023-03-14 15:55:39
 * @Description: 报价管理
 */

import View from '@/components/view';

const routes = [
    {
        path: 'score-manage',
        component: View,
        meta: {
            title: '综合评分管理'
        },
        children: [
            {
                path: 'enterprise-statistical',
                component: () => import(/* webpackChunkName: "enterprise-statistical" */ '@/pages/aup/manage-org/proj-inner/score-manage/enterprise-statistical'),
                meta: {
                    title: '企业指标评审过程统计'
                }
            },
            {
                path: 'product-statistical',
                component: () => import(/* webpackChunkName: "product-statistical" */ '@/pages/aup/manage-org/proj-inner/score-manage/product-statistical'),
                meta: {
                    title: '品种指标评审过程统计'
                }
            },
            {
                path: 'enterprise-statistical/score-detail',
                component: () => import(/* webpackChunkName: "enterprise-statistical-detail" */ '@/pages/aup/manage-org/proj-inner/score-manage/expert-score-detail/enterprise-detail'),
                meta: {
                    title: '评审详情',
                    display: 'none'
                }
            },
            {
                path: 'product-statistical/score-detail',
                component: () => import(/* webpackChunkName: "product-statistical-detail" */ '@/pages/aup/manage-org/proj-inner/score-manage//expert-score-detail/product-detail'),
                meta: {
                    title: '评审详情',
                    display: 'none'
                }
            },
            {
                path: 'enterprise-check',
                component: () => import(/* webpackChunkName: "enterprise-check-list" */ '@/pages/aup/manage-org/proj-inner/score-manage/enterprise-check/index'),
                meta: {
                    title: '企业综合实力评审核对'
                }
            },
            {
                path: 'enterprise-check/check',
                component: () => import(/* webpackChunkName: "enterprise-check" */ '@/pages/aup/manage-org/proj-inner/score-manage/enterprise-check/check'),
                meta: {
                    title: '进入核对',
                    display: 'none'
                }
            },
            {
                path: 'product-check',
                component: () => import(/* webpackChunkName: "product-check-list" */ '@/pages/aup/manage-org/proj-inner/score-manage/product-check/index'),
                meta: {
                    title: '品种保障能力评审核对'
                }
            },
            {
                path: 'product-check/check',
                component: () => import(/* webpackChunkName: "product-check" */ '@/pages/aup/manage-org/proj-inner/score-manage/product-check/check'),
                meta: {
                    title: '进入核对',
                    display: 'none'
                }
            },
            {
                path: 'score-result-publish',
                component: () => import(/* webpackChunkName: "score-result-publish-list" */ '@/pages/aup/manage-org/proj-inner/score-manage/score-result-publish/index'),
                meta: {
                    title: '综合评审结果发布'
                }
            },
            { path: 'score-result-calc',
                component: () => import(/* webpackChunkName: "score-result-calc-list" */ '@/pages/aup/manage-org/proj-inner/score-manage/score-result-calc/index'),
                meta: {
                    title: '综合评审结果计算'
                }
            },
            {
                path: 'review-result-recheck',
                component: () => import(/* webpackChunkName: "review-result-recheck" */ '@/pages/aup/manage-org/proj-inner/score-manage/review-result-recheck'),
                meta: {
                    title: '专家评审结果复查'
                }
            },
            {
                path: 'review-result-recheck/product-recheck',
                component: () => import(/* webpackChunkName: "review-result-recheck-product" */ '@/pages/aup/manage-org/proj-inner/score-manage/review-result-recheck/product-recheck'),
                meta: {
                    title: '复查',
                    display: 'none'
                }
            },
            {
                path: 'review-result-recheck/company-recheck',
                component: () => import(/* webpackChunkName: "review-result-recheck-company" */ '@/pages/aup/manage-org/proj-inner/score-manage/review-result-recheck/company-recheck'),
                meta: {
                    title: '复查',
                    display: 'none'
                }
            },
            {
                path: 'audit-conclusion-calc',
                component: () => import(/* webpackChunkName: "audit-conclusion-calc" */ '@/pages/aup/manage-org/proj-inner/score-manage/audit-conclusion-calc'),
                meta: {
                    title: '指标初审结果计算'
                }
            },
            {
                path: 'org-score-publicity',
                component: () => import(/* webpackChunkName: "org-score-publicity" */ '@/pages/aup/manage-org/proj-inner/score-manage/org-score-publicity'),
                meta: {
                    title: '企业综合得分公示'
                }
            }
        ]
    }
];

export default routes;
