/*
 * @Author: hejk
 * @Email: hejk@hxmec.com
 * @Date: 2019-12-10 18:04:09
 * @Last Modified by: zhujial
 * @Last Modified time: 2023-03-04 13:13:54
 * @Description: 复审
 */

import View from '@/components/view';

const routes = [
    {
        path: 'reAudit-score',
        component: View,
        meta: {
            title: '复审'
        },
        children: [
            {
                path: 'enterprise-statistical',
                component: () => import(/* webpackChunkName: "reAudit-enterprise-statistical" */ '@/pages/aup/manage-org/proj-inner/reAudit-score/enterprise-statistical'),
                meta: {
                    title: '企业指标复审过程统计'
                }
            },
            {
                path: 'product-statistical',
                component: () => import(/* webpackChunkName: "reAudit-product-statistical" */ '@/pages/aup/manage-org/proj-inner/reAudit-score/product-statistical'),
                meta: {
                    title: '品种指标复审过程统计'
                }
            },
            {
                path: 'enterprise-statistical/score-detail',
                component: () => import(/* webpackChunkName: "reAudit-enterprise-statistical-detail" */ '@/pages/aup/manage-org/proj-inner/reAudit-score/expert-score-detail/enterprise-detail'),
                meta: {
                    title: '评审详情',
                    display: 'none'
                }
            },
            {
                path: 'product-statistical/score-detail',
                component: () => import(/* webpackChunkName: "reAudit-product-statistical-detail" */ '@/pages/aup/manage-org/proj-inner/reAudit-score/expert-score-detail/product-detail'),
                meta: {
                    title: '评审详情',
                    display: 'none'
                }
            },
            {
                path: 'enterprise-check',
                component: () => import(/* webpackChunkName: "reAudit-enterprise-check-list" */ '@/pages/aup/manage-org/proj-inner/reAudit-score/enterprise-check/index'),
                meta: {
                    title: '企业综合实力复审核对'
                }
            },
            {
                path: 'enterprise-check/check',
                component: () => import(/* webpackChunkName: "reAudit-enterprise-check" */ '@/pages/aup/manage-org/proj-inner/reAudit-score/enterprise-check/check'),
                meta: {
                    title: '进入核对',
                    display: 'none'
                }
            },
            {
                path: 'product-check',
                component: () => import(/* webpackChunkName: "reAudit-product-check-list" */ '@/pages/aup/manage-org/proj-inner/reAudit-score/product-check/index'),
                meta: {
                    title: '品种保障能力复审核对'
                }
            },
            {
                path: 'product-check/check',
                component: () => import(/* webpackChunkName: "reAudit-product-check" */ '@/pages/aup/manage-org/proj-inner/reAudit-score/product-check/check'),
                meta: {
                    title: '进入核对',
                    display: 'none'
                }
            },
            {
                path: 'audit-conclusion-calc',
                component: () => import(/* webpackChunkName: "audit-conclusion-calc" */ '@/pages/aup/manage-org/proj-inner/reAudit-score/audit-conclusion-calc'),
                meta: {
                    title: '指标复审结果计算'
                }
            }
        ]
    }
];

export default routes;
