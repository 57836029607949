
/*
 * @Author: zhangzc
 * @Email: zhangzc2@guahao.com
 * @Date: 2019-07-05 16:52:10
 * @Last Modified by: zhujial
 * @Last Modified time: 2023-12-18 10:55:55
 * @Description: 工具函数
 * @Route: Route
 */
import CryptoJS from 'crypto-js';


import { isOrNotOptionsOutAll } from '@/utils/options/aatl-options';

import { getOrgInfo } from '@/api';

/**
 * 流写入文件
 * @param {String} data 文件流
 * @param {String} filename 文件名
 * @param {String} type 文件流格式
 */

export const writeBlobToFile = (data, filename, type = 'application/vnd.ms-excel') => {
    const blob = new Blob([data], {
        type
    });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
        const oA = document.createElement('a');
        oA.href = URL.createObjectURL(blob);
        oA.download = filename;
        oA.click();
    }
};
/*
 * @description 获取地址栏参数
 */
export const getUrlParame = paramName => {
    const urlParams = {};
    const url = window.location.href;
    const idx = url.indexOf('?');
    if (idx === -1) {
        return false;
    }
    let params = url.substring(url.indexOf('?') + 1);
    params = params.split('#');
    params = params[0].split('&');
    params.forEach(item => {
        const param = item.split('=');
        urlParams[param[0]] = decodeURIComponent(param[1]);
    });
    if (paramName) {
        return urlParams[paramName];
    }
    return urlParams;
};

/*
 * @description 获取地址栏hash参数
 */
export const getUrlHash = paramName => {
    const urlParams = {};
    const url = window.location.href;
    const idx = url.indexOf('#');
    if (idx === -1) {
        return false;
    }
    let params = url.substring(url.indexOf('#') + 1);
    params = params.split('&');
    params.forEach(item => {
        const param = item.split('=');
        urlParams[param[0]] = decodeURIComponent(param[1]);
    });
    if (paramName) {
        return urlParams[paramName];
    }
    return urlParams;
};

/*
 * 判断浏览器类型是否为IE
 */
export const isIE = () => {
    const u = window.userAgent || navigator.userAgent;
    return u.indexOf('MSIE') > 0 || u.indexOf('Trident') > 0;
};

/*
 * 判断对象是否为空
 */
export const isEmptyObj = obj => (
    !obj || Object.keys(obj).length === 0
);


/**
 * 日期格式化
 * (new Date(), 'yyyy-mm-dd hh:ii:ss.S')==> 2006-07-02 08:09:04.423
 * (new Date(), 'yyyy-mm-dd E HH:ii:ss') ==> 2009-03-10 二 20:09:04
 * (new Date(), 'yyyy-mm-dd EE hh:ii:ss') ==> 2009-03-10 周二 08:09:04
 * (new Date(), 'yyyy-mm-dd EEE hh:ii:ss') ==> 2009-03-10 星期二 08:09:04
 * (new Date(), 'yyyy-m-d h:m:s.S') ==> 2006-7-2 8:9:4.18
 * (new Date(), 'yyyy/mm/dd hh:ii:ss.S') ==> 2017/04/17 10:00:48.282
*/
export const formatDate = (value, fmt) => {
    if (!value) {
        return null;
    }
    // ie
    let date = value;
    if (String(date).match('-')) {
        date = date.replace('-', '/');
    }
    /* eslint-disable no-param-reassign */
    date = new Date(date);
    /* eslint no-console: 'off' */
    if (date.toString() === 'Invalid Date') {
        console.log('日期不合法');
    }
    const o = {
        'M+': date.getMonth() + 1, // 月份,
        'm+': date.getMonth() + 1, // 月份
        'd+': date.getDate(), // 日
        'h+': date.getHours() % 24 === 0 ? 0 : date.getHours() % 24, // 小时
        'H+': date.getHours(), // 小时
        'i+': date.getMinutes(), // 分
        's+': date.getSeconds(), // 秒
        'q+': Math.floor((date.getMonth() + 3) / 3), // 季度
        S: date.getMilliseconds() // 毫秒
    };
    const week = {
        0: '/u65e5',
        1: '/u4e00',
        2: '/u4e8c',
        3: '/u4e09',
        4: '/u56db',
        5: '/u4e94',
        6: '/u516d'
    };
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (`${date.getFullYear()}`).substr(4 - RegExp.$1.length));
    }
    if (/(E+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (match, p1) => {
            if (p1.length > 1) {
                return (p1.length > 2 ? '/u661f/u671f' : '/u5468') + week[`${date.getDay()}`];
            }
            return week[`${date.getDay()}`];
        });
    }
    const keys = Object.keys(o);
    for (let k = 0, len = keys.length; k < len; k += 1) {
        const prop = keys[k];
        if (new RegExp(`(${prop})`).test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ?
                (o[prop]) : ((`00${o[prop]}`).substr((`${o[prop]}`).length)));
        }
    }
    /* eslint-disable consistent-return */
    return fmt;
};

/**
 * 判断是否为日期
 * @param {*} date 要判断是否为日期的值
 */
export const isDate = date => {
    if (date === null || date === undefined) return false;
    if (Number.isNaN(new Date(date).getTime())) return false;
    if (Array.isArray(date)) return false; // deal with `new Date([ new Date() ]) -> new Date()`
    return true;
};

/**
 * 获取指定时间前几个月或几年的时间戳
 * @param {timestamp} time 目标时间
 * @param {Object} obj 时间区间跨度
 * @param {String || Number} obj.range 时间区间
 * @param {String} obj.dimension 时间维度：年 || 月
 */
export const getHistoryRangeTimestamp = (time, obj = {}) => {
    if (!isDate(time)) return;
    const { range = 1, dimension = 'year' } = obj;
    const year = new Date(time).getFullYear();
    const month = new Date(time).getMonth() + 1;
    const afterMonth = month - 6;
    let yearR = year - range;
    let monthR = month;
    if (dimension === 'month') {
        yearR = afterMonth < 1 ? year - 1 : year;
        monthR = afterMonth < 1 ? month + 6 : month - 6;
    }
    return new Date(`${yearR}-${monthR}`).getTime();
};

/*
 * 下载文件
 */
export const downloadFile = url => {
    const iframeEle = document.createElement('iframe');
    iframeEle.src = url;
    iframeEle.style.display = 'none';
    document.body.appendChild(iframeEle);
};
/**
 * 计时
 * @param {String} target 目标时间 格式：yyyy-mm-dd hh:mm:ss
 */
export const Timing = target => {
    const newDate = new Date();// 获取当前日期对象
    const newTime = newDate.getTime();// 现在距离1970年的毫秒数
    let oDate;
    if (typeof target === 'string') {
        oDate = new Date(Date.parse(target.replace(/-/g, '/'))); // 对ie进行兼容
    } else {
        oDate = new Date(target);
    }

    const oldTime = oDate.getTime();// 2019年距离1970年的毫秒数
    if (newTime >= oldTime) {
        let second = Math.floor((newTime - oldTime) / 1000);// 未来时间距离现在的秒数
        const day = Math.floor(second / 86400);// 整数部分代表的是天；一天有24*60*60=86400秒 ；
        second %= 86400;// 余数代表剩下的秒数；
        let hour = Math.floor(second / 3600);// 整数部分代表小时；
        second %= 3600; // 余数代表 剩下的秒数；
        let minute = Math.floor(second / 60);
        second %= 60;
        if (parseInt(hour, 10) < 10) {
            hour = `0${hour}`;
        }
        if (parseInt(second, 10) < 10) {
            second = `0${second}`;
        }
        if (parseInt(minute, 10) < 10) {
            minute = `0${minute}`;
        }
        return {
            day: day || '00',
            hour: hour || '00',
            minute: minute || '00',
            second: second || '00'
        };
    }
    return {
        day: '00',
        hour: '00',
        minute: '00',
        second: '00'
    };
};
/**
 * 文件流导出
 */
export const downloadBlob = (res, filename, type = 'application/vnd.ms-excel') => {
    const blob = new Blob([res], {
        type
    });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
        const oA = document.createElement('a');
        oA.href = URL.createObjectURL(blob);
        oA.download = filename;
        oA.click();
    }
};

/*
 * 深度遍历获取目标
 * param {Array} source 源数据
 * param {String} target 目标值
 */
export const findTargetByDeepTraverse = (source, target, result = []) => {
    const localSource = JSON.parse(JSON.stringify(source));
    localSource.forEach(item => {
        if (item.name.indexOf(target) !== -1) {
            result.push(item);
        } else if (item.children && item.children.length) {
            findTargetByDeepTraverse(item.children, target, result);
        }
    });
    return result;
};

/*
 * 区分监管端项目内耗材和药品
 * param {String} path 页面路径
 */
export const findProjectType = path => {
    const drugReg = /\/drug\//;
    const conReg = /\/consumable\//;
    let urlStr = '';
    if (drugReg.test(path)) {
        urlStr = 'drug';
    }
    if (conReg.test(path)) {
        urlStr = 'consumable';
    }
    return urlStr;
};

/**
 * 倒计时
 * @param {String} target 目标时间
 */
export const countDown = target => {
    const oDate = new Date();// 获取日期对象
    const oldTime = oDate.getTime();// 现在距离1970年的毫秒数
    const newDate = new Date(target);
    const newTime = newDate.getTime();// 2019年距离1970年的毫秒数
    if (newTime >= oldTime) {
        let second = Math.floor((newTime - oldTime) / 1000);// 未来时间距离现在的秒数
        const day = Math.floor(second / 86400);// 整数部分代表的是天；一天有24*60*60=86400秒 ；
        second %= 86400;// 余数代表剩下的秒数；
        const hour = Math.floor(second / 3600);// 整数部分代表小时；
        second %= 3600; // 余数代表 剩下的秒数；
        const minute = Math.floor(second / 60);
        second %= 60;
        return {
            day,
            hour,
            minute,
            second
        };
    }
    return false;
};

/*
 * param {String} word 待加密的字符串
 * param {String} keyStr aes加密需要用到的16位字符串的key
 */
export const encrypt = (word, keyStr) => {
    keyStr = keyStr || 'Lic6J8807nW5K674';
    const key = CryptoJS.enc.Utf8.parse(keyStr);
    const srcs = CryptoJS.enc.Utf8.parse(word);
    const encrypted = CryptoJS.AES.encrypt(
        srcs,
        key,
        { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }
    );
    return encrypted.toString();
};

/*
 * param {String} word 待加密的字符串
 * param {String} keyStr aes加密需要用到的16位字符串的key
 */
export const encrypt2 = data2 => {
    let key = '2dskjk3j42hjkdsh';
    let result = '';
    key = CryptoJS.enc.Latin1.parse(key);
    const iv = key;
    // 加密
    const encrypted = CryptoJS.AES.encrypt(
        data2,
        key, {
            iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.ZeroPadding
        }
    );
    result = encrypted.toString();
    return result;
};


/*
 * param {String} word 待解密的字符串
 * param {String} keyStr aes解密需要用到的16位字符串的key
 */
export const decrypt = (word, keyStr) => {
    keyStr = keyStr || 'Lic6J8807nW5K674';
    const key = CryptoJS.enc.Utf8.parse(keyStr);
    const decrypted = CryptoJS.AES.decrypt(
        word,
        key,
        { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 }
    );
    return CryptoJS.enc.Utf8.stringify(decrypted).toString();
};

/**
 * 对象深度拷贝
 * @param {Object} obj 拷贝对象
 */
export const deepCopy = obj => (function deepcopy(oldObj) {
    // 定义一个新的空对象
    let newObject = {};
    // 判断原对象是否存在
    if (oldObj) {
        // 判断原对象的类型
        if (oldObj.constructor === Object) {
            newObject = new oldObj.constructor();
        } else {
            newObject = new oldObj.constructor(oldObj.valueOf());
        }
        // 遍历克隆原对象的属性
        Object.keys(oldObj).forEach(key => {
            if (newObject[key] !== oldObj[key]) {
                if (typeof (oldObj[key]) === 'object') {
                    // 对象内部的子对象
                    newObject[key] = deepcopy(oldObj[key]);
                } else {
                    newObject[key] = oldObj[key];
                }
            }
        });
        // 克隆原对象的常用方法
        // newObject.toString = oldObj.toString;
        // newObject.valueOf = oldObj.valueOf;
        return newObject;
    }
}(obj));

/**
 * 对象深度合并
 * @param {Object} initObj 原始对象
 * @param {Object} targetObj 合并对象
 */
export const deepMergeObj = (initObj, targetObj) => {
    const resultObj = initObj;
    const keys = Object.keys(targetObj);
    keys.forEach(key => {
        resultObj[key] = initObj[key] && initObj[key].toString() === '[object Object]' ? deepMergeObj(initObj[key], targetObj[key]) : targetObj[key];
    });
    return resultObj;
};

export const dataConvert = convert => {
    if (typeof convert === 'string') {
        convert = parseFloat(convert);
    }
    if (convert >= 10000 && convert < 10000 * 10000) {
        convert /= 10000;
        convert = `${convert.toFixed(2)}万`;
    } else if (convert >= 10000 * 10000) {
        convert /= (10000 * 10000);
        convert = `${convert.toFixed(2)}亿`;
    }
    return convert;
};

/**
 * 统一处理数据字典
 * @param { Ojbect } res 后端返回数据
 */
export const handleSysDic = res => {
    const { data } = res;
    let list = [];
    if (Array.isArray(data)) {
        list = data.map(item => {
            const obj = {
                label: item.value,
                value: item.label
            };
            return obj;
        });
    }
    return list;
};

/**
 * 字符串转换位数字
 * @param { String } str
 */
export const string2num = str => (Number.isNaN(parseFloat(str)) === true ? 0 : parseFloat(str));

/**
 * 列表本页合计数据
 * @param { Array } records 后端返回数据
 * @param { Array } totalData 本页合计需要字段 eg:[{name:'',unit:''}]
*/
export const getPageTotal = (records, totalData) => {
    const totalItem = {};
    for (let t = 0; t < totalData.length; t += 1) {
        const { name, unit = '' } = totalData[t];
        totalItem[name] = 0;
        for (let i = 0; i < records.length; i += 1) {
            const recordItem = records[i];
            totalItem[name] += string2num(recordItem[name]);
        }
        totalItem[name] = totalItem[name].toFixed(4);
        totalItem[name] += unit;
    }
    return totalItem;
};
/**
 * 统一处理数据字典-交易结算
 * @param { Ojbect } res 后端返回数据
 */
export const handleSysDicTrade = res => {
    const { data } = res;
    let list = [];
    if (Array.isArray(data)) {
        list = data.map(item => {
            const obj = {
                label: item.desc,
                value: item.value
            };
            return obj;
        });
    }
    return list;
};
/**
 * 统一返回的数据字典是否的过滤
 * @param { Ojbect } row,column,cellValue-对应的后端返回值
 */
export const formatterIsOrNo = (row, column, cellValue) => {
    if (cellValue !== undefined && cellValue !== null && cellValue !== '') {
        let choseItem = {
            label: ''
        };
        if (typeof (cellValue) === 'number') {
            if (cellValue === 1 || cellValue === '1') {
                return '是';
            }
            return '否';
        }
        choseItem = isOrNotOptionsOutAll.find(item => item.value === cellValue);

        return choseItem.label;
    }
    return '否';
};

/**
 * 统一处理机构信息的弹出框
 * @param { Ojbect } id 机构或者企业id type:1,生产企业，2配送企业，3医疗机构
 */
export const getAndShowDialogOrgInfo = (id, type) => {
    if (id) {
        getOrgInfo({ orgId: id }).then(res => {
            console.log(res);
            if (res.code === 0 && res.data !== null) {
                const { data } = res;
                const types = {
                    1: '生产企业详情',
                    2: '配送企业详情',
                    3: '医疗机构详情'
                };
                window.App.$alert(`${'<div class="alertForm">' +
                    '<div class="form-line"><div class="leftName">企业名称：</div><div class="rightText">'}${data.orgName || ''}</div></div>` +
                    `<div class="form-line"><div class="leftName">联系人：</div><div class="rightText">${data.contactName || ''}</div></div>` +
                    `<div class="form-line"><div class="leftName">地址：</div><div class="rightText">${data.city || ''}${data.area || ''}${data.registerAddr || ''}</div></div>` +
                    `<div class="form-line"><div class="leftName">联系人手机号：</div><div class="rightText">${data.contactMobile || ''}</div></div>` +
                    '</div>', types[type], {
                    dangerouslyUseHTMLString: true,
                    closeOnClickModal: true
                });
            } else {
                window.App.$message.error(res.msg);
            }
        });
    }
};


/**
 * 比对组件切换只修改部分
 * @param {Boolean} true 显示修改部分  false  显示全部
 * @param {String} 外层父类class
 */
export const showContrastDiff = (flag = false, parentClass) => {
    const display = flag ? 'none' : 'table-row';
    const rowArr = document.querySelectorAll(`.${parentClass} .el-table__row`);
    Object.keys(rowArr).forEach(idx => {
        const item = rowArr[idx];
        const children = item.childNodes;
        let isDiff = false;
        Object.keys(children).forEach(tItem => {
            if (children[tItem].className.indexOf('dif-border') !== -1) {
                isDiff = true;
            }
        });
        if (!isDiff) {
            item.style.display = display;
        }
    });
};

/**
 * 日期格式转换 eg:2019-12-12 => 2019/12/12
 */
export const dateFormatConversion = date => {
    if (date) {
        date = date.replace(/-/g, '/');
    }
    return date;
};

/**
 * 判断是否为二维数组
 * @param {} row 
 * @param {*} column 
 * @param {*} cellValue 
 */
export const isDimensArray = arr => {
    if (!Array.isArray(arr)) {
        return false;
    }
    return arr.every(item => Array.isArray(item));    
};

/**
 * 通过文件Id获取文件url
 * @param {Number} fileId 文件id
 */
export const getFileUrl = fileId => new Promise((resolve, reject) => {
    window.App.$fetch({
        url: window.App.$bkConfig.filePath,
        method: 'get',
        params: {
            id: fileId
        }
    }).then(res => {
        if (res.code !== 0) {
            reject(res.msg);
        } else {
            resolve(res.data.url);
        }
    });
});
/**
 * 保留小数点位数
 * @param {String/Number} val 转换的值
 * @param {Number} digit 保留小数点位数
 */
export const num2Fixed = (val, digit = 2) => {
    val = parseFloat(val);
    if (Number.isNaN(val)) {
        return '';
    }
    return val.toFixed(digit);
};

/**
 * 获取文件后缀
 * @param {Object} 文件信息
 */
export const getFileType = file => {
    const arr = file.name.split('.');
    return arr[arr.length - 1].toLowerCase();
};

// 提示方法
export const alert = err => {
    if (window.App && window.App.$message) {
        window.App.$message.error(err);
    } else {
        console.warn(err);
    }
};
export const getRandom = (n = new Date().getTime()) => {
    const a = parseInt(n + (`${Math.random()}`).substr(3, 6), 10);
    return String(a);
};
/**
 * 检查是否是ie
 * */
export const ie = () => {
    const u = window.navigator.userAgent;
    let is = false;
    if (u.indexOf('Trident') > -1 && u.indexOf('rv:11.0') > -1) {
        // IE11
        is = 11;
    } else if (u.indexOf('compatible') > -1 && u.indexOf('MSIE') > -1) {
        const reIE = new RegExp('MSIE (\\d+\\.\\d+);');
        reIE.test(u);
        is = parseFloat(RegExp.$1);
    }
    return is;
};

export const ajax = option => {
    /**
    * 请求
    */
    function ajax() {
        const ajaxData = {
            type: arguments[0].type || 'Post',
            url: arguments[0].url || '',
            async: arguments[0].async || true,
            data: arguments[0].data || null,
            dataType: arguments[0].dataType || 'text',
            contentType: arguments[0].contentType === undefined ? 'application/json' : arguments[0].contentType,
            beforeSend: arguments[0].beforeSend || function () { },
            success: arguments[0].success || function () { },
            error: arguments[0].error || function () { }
        };
        ajaxData.beforeSend();
        const xhr = createxmlHttpRequest();
        xhr.open(ajaxData.type, ajaxData.url, ajaxData.async);
        // 嘉恒stampFile会报错
        if (ajaxData.contentType !== false) {
            xhr.setRequestHeader('Content-Type', ajaxData.contentType);
        }
        if (ajaxData.dataType === 'blob') {
            xhr.responseType = 'blob';
        }
        if (ajaxData.type.toUpperCase() === 'POST') {
            const json = JSON.stringify(ajaxData.data);
            xhr.send(json);
        } else {
            xhr.send(convertData(ajaxData.data));
        }
        
        xhr.onload = () => {
            const res = xhr.response;
            if (res && xhr.readyState === XMLHttpRequest.DONE && xhr.status === 200) {
                let res = xhr.response;
                if (typeof res === 'string' && ajaxData.dataType === 'text') {
                    // const reg = /\\u001fw/g
                    // res = JSON.stringify(res).replace(reg, ''); // 凯特数据处理
                    res = JSON.parse(res);
                    ajaxData.success(res);
                } else {
                    ajaxData.success(res);
                }
            } else {
                ajaxData.error(res);
            }
        };
        xhr.onerror = e => {
            const res = xhr.response;
            ajaxData.error(res);
        };
    }
    function createxmlHttpRequest() {
        if (window.XMLHttpRequest) { // Mozilla 浏览器
            return new XMLHttpRequest();
        } 
        if (window.ActiveXObject) {
            try {
                return new ActiveXObject('Microsoft.XMLHTTP');
            } catch (e) {
                try {
                    return new ActiveXObject('Msxml2.XMLHTTP');
                } catch (e) {
                }
            }
        }
    }
    function convertData(data) {
        if (data && typeof data === 'object') {
            let convertResult = '';
            Object.keys(data).forEach(c => {
                const val = data[c];
                if (convertResult) convertResult += '&';
                convertResult += `${c}=${encodeURIComponent(val)}`;
            });
            convertResult = convertResult.substring(0, convertResult.length - 1);
            return convertResult;
        }
        return encodeURIComponent(data);
    }
    return ajax(option);
};
